import React from "react";
// import Image from "../Hacks/Image";

export const FixtureHeaderBBC = ({
  Background,
  date,
  leftBadge,
  leftBadgeAlt,
  leftScore,
  leftScorers,
  leftTeam,
  rightBadge,
  rightBadgeAlt,
  rightScore,
  rightScorers,
  rightTeam,
  venue,
  type,
}) => (
  <header className="fixture-header bg-primary container-fluid pt-5 pb-4 pb-lg-5 overflow-hidden position-relative">
    {!!Background && (
      <div className="hero-bg d-block">
        <img src={Background} alt="" />
      </div>
    )}
    <div className="py-lg-5">
      <div className="text-center pt-4 pt-lg-0">
        <div className="post-meta font-weight-bold pt-md-5">{date}</div>
        <div className="post-meta mt-1">{venue}</div>
        <div className="post-meta mt-1">{type}</div>
      </div>
      <h1 className="fixture-title row justify-content-center text-center mt-4  mt-lg-5">
        <div className="col-lg-4 text-left text-lg-right">
          <div className="row">
            <div className="col-2 offset-md-1 col-md-1 col-lg-2 offset-lg-0">
              {leftBadge && (
                <img
                  src={leftBadge}
                  className="fixture-badge img-fluid mb-3 col-xl-3 p-0"
                  alt={leftBadgeAlt}
                />
              )}
            </div>
            <div className="col-8">
              <div className="h1">{leftTeam}</div>
              <div className="h6 font-weight-normal">{leftScorers}</div>
            </div>
            <div className="col-2 text-lg-right h1">
              <span className="bg-success px-2 py-1 mr-1 mr-md-2">
                {leftScore}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="col-lg-3 h1 d-none d-lg-block">V</div> */}

        <div className="col-lg-4 text-left mt-3 mt-lg-0">
          <div className="row">
            <div className="col-2 offset-md-1 col-md-1 col-lg-2 offset-lg-0 order-lg-3">
              {leftBadge && (
                <img
                  src={rightBadge}
                  className="fixture-badge img-fluid mb-3 col-xl-3 p-0"
                  alt={rightBadgeAlt}
                />
              )}
            </div>
            <div className="col-8 order-lg-2">
              <div className="h1">{rightTeam}</div>
              <div className="h6 font-weight-normal">{rightScorers}</div>
            </div>
            <div className="col-2 order-lg-0 h1">
              <span className="bg-success px-2 py-1 mr-1 mr-md-2">
                {rightScore}
              </span>
            </div>
          </div>
        </div>
      </h1>
    </div>
  </header>
);

export default FixtureHeaderBBC;
