import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import Layout from "../components/Layout";

import useSiteMetadata from "../components/SiteMetadata";
import Seo from "../components/General/Seo";

import FixtureHeaderBBC from "../components/fixtures/FixtureHeaderBBC";
import FixtureSquad from "../components/fixtures/fixtureSquad";
import ContentBlock from "../components/Hacks/Content";

import ClubBadge from "./../../static/img/SSHAFC-BADGE-BLACK-PINK.svg";

const BlogPost = ({ data }) => {
  const { meta, social } = useSiteMetadata();

  const {
    Image,
    HomeAway,
    Notes,
    Time,
    Location,
    OpponentName,
    Goals,
    Conceded,
    OpponentBadge,
    gameID,
  } = data.googleGamesTrainingFinesSheet;

  const players = data.allGooglePlayersInSessionsSheet.nodes;
  let scorers = [];
  // let players = [];
  for (const player of players) {
    if (player.Goals > 0) {
      if (player.Goals === 1) {
        scorers.push(player.PlayerName);
      } else {
        scorers.push(`${player.PlayerName} x${player.Goals}`);
      }
    }
  }

  return (
    <Layout>
      <Seo
        title={
          `Seven Hills AFC vs ${OpponentName}` +
          meta.sitePageTitleSep +
          `Results` +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={`${meta.siteUrl}/results/${gameID}`}
        // description={
        //   post.frontmatter.metaDescription
        //     ? post.frontmatter.metaDescription
        //     : post.frontmatter.excerpt
        //     ? post.frontmatter.excerpt
        //     : meta.blogDescription
        // }
        // image={
        //   meta.siteUrl +
        //   (post.frontmatter.metaImage
        //     ? post.frontmatter.metaImage.publicURL
        //     : post.frontmatter.image && post.frontmatter.image.publicURL
        //     ? post.frontmatter.image.publicURL
        //     : meta.siteImage)
        // }
        twitterTitle={`Seven Hills AFC vs ${OpponentName}`}
        facebookTitle={`Seven Hills AFC vs ${OpponentName}`}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <header>
        <FixtureHeaderBBC
          Background={Image?.localFiles[0]?.publicURL}
          date={Time}
          venue={Location}
          leftBadge={
            HomeAway === "Home"
              ? ClubBadge
              : OpponentBadge
          }
          // leftBadgeAlt={leftBadgeAlt}
          leftTeam={HomeAway === "Home" ? `Seven Hills AFC` : OpponentName}
          leftScore={HomeAway === "Home" ? Goals : Conceded}
          leftScorers={HomeAway === "Home" ? scorers.join(", ") : null}
          rightBadge={
            HomeAway === "Home"
              ? OpponentBadge
              : ClubBadge
          }
          rightTeam={HomeAway !== "Home" ? `Seven Hills AFC` : OpponentName}
          rightScore={HomeAway === "Home" ? Conceded : Goals}
          rightScorers={HomeAway === "Home" ? null : scorers.join(", ")}
          // rightBadgeAlt={rightBadgeAlt}
        />
      </header>
      <main className="fixturecontent container-fluid py-4 py-lg-5 mb-lg-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            {!Notes?.includes("<iframe") && Image?.localFiles[0]?.publicURL && (
              <img
                src={Image?.localFiles[0]?.publicURL}
                alt=""
                className="mb-4"
              />
            )}
            <ContentBlock content={Notes} />
          </div>
          <div className="col-lg-3 offset-lg-1">
            {players.length > 0 ? (
              <FixtureSquad squad={players} />
            ) : (
              <div className="text-lg-right">Awaiting Squad...</div>
            )}
          </div>
        </div>
      </main>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query FixtureByID($recordId: String!) {
    allGooglePlayersInSessionsSheet(filter: { game: { eq: $recordId } }) {
      nodes {
        id
        PlayerName: player
        Starter: starterSub
        Goals: goals
        Assists: assists
        MoM: mOM
        RunnerUpMOM: runnerUpMOM
      }
    }

    googleGamesTrainingFinesSheet(gameID: { eq: $recordId }) {
      id
      gameID
      # data {
      #   Image {
      #     localFiles {
      #       publicURL
      #     }
      #   }
      OpponentName: team
      OpponentBadge: badge
      #   localFiles {
      #     publicURL
      #   }
      # }
      Time: formattedDate
      HomeAway: home_Away
      Goals: scoreUs
      Conceded: scoreThem
      Location: venue
      Notes: description
      urlPath: gameID
      # }
    }
  }
`;
