import React from "react";

export default function fixtureSquad({ squad }) {
  if (squad) {
    var squadOrdered = squad.sort(function (a, b) {
      return a.squadnum - b.squadnum;
    });
  }

  return (
    <aside>
      <table className="rounded-sm shadow overflow-hidden table table-dark bg-primary table-hover table-striped table-bordered small">
        <thead>
          <tr>
            <th colSpan="3" className="text-center">
              <h4 className="mb-0">Seven Hills AFC Team</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          {squadOrdered &&
            squadOrdered.map((player) => (
              <tr key={player.id} className="">
                {player.Number && (
                  <td className="text-center font-weight-bold">
                    {player.Number}
                  </td>
                )}
                <td className="font-weight-bold ">{player.PlayerName}</td>
                <td className="text-right text-success">
                  <span className="small">
                    {!!player.Goals && (
                      <div className="">
                        GOALS x {player.Goals}
                      </div>
                    )}
                    {!!player.Assists && (
                      <div className="">
                        ASSISTS x{player.Assists}
                      </div>
                    )}
                    {player.MoM && (
                      <div className="font-weight-bold">MOM</div>
                    )}
                    {player.RunnerUpMOM && (
                      <div className="font-weight-bold">Runner Up MOM</div>
                    )}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </aside>
  );
}
